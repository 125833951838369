<template>
  <div>
    <v-simple-table dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Investment Plan</th>
            <th class="text-left">Investment Range ($)</th>
            <th class="text-center">Duration (Months)</th>
            <th class="text-left">Daily Profit (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in getInvestmentPlan" :key="item.name">
            <td>
              <v-radio-group v-model="radioGroup">
                <v-radio
                  color="#d4b506"
                  :value="item"
                  :label="item.investmentName"
                ></v-radio>
              </v-radio-group>
            </td>
            <td>
              {{ DisplayCurrency(item.lowestInvestmentAmount) }} -
              {{ DisplayCurrency(item.hightestInvestmentAmount) }}
            </td>
            <td class="text-center">{{ item.duration }}</td>
            <td>{{ item.dailyReturnRate }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto ma-8" max-width="400">
          <div>
            <v-list subheader>
              <v-list-item v-if="radioGroup">
                <v-list-item-content>
                  <v-list-item-title>
                    <b> {{ radioGroup.investmentName }}</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-list-item-title>
                      <b>
                        {{ DisplayCurrency(radioGroup.lowestInvestmentAmount) }}
                        -
                        {{
                          DisplayCurrency(radioGroup.hightestInvestmentAmount)
                        }}
                      </b>
                    </v-list-item-title>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <b> Amount to Invest ($): </b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-list-item-title>
                      <v-text-field
                        label="Invest Amount"
                        filled
                        dense
                        autofocus
                        type="number"
                        v-model="amount"
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-radio-group class="ml-5" v-model="radio">
              <v-radio
                color="#d4b506"
                label="BITCOIN"
                value="BITCOIN"
              ></v-radio>
              <v-radio color="#d4b506" label="BNB" value="BNB"></v-radio>
              <v-radio
                color="#d4b506"
                label="ETHEREUM"
                value="ETHEREUM"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="text-center pa-5">
            <v-btn @click="Deposit()" large color="#d4b506" light>
              Deposit
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import currencyFormatter from "currency-formatter";

export default {
  data() {
    return {
      radioGroup: "",
      radio: "",
      amount: "",
    };
  },
  created() {
    this.GetInvestmentPlan();
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    Deposit() {
      if (this.radioGroup == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Select investment plan");
        return;
      }
      if (this.amount == "") {
        console.log(this.radioGroup);
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Enter investment Amount");
        return;
      }

      if (
        parseFloat(this.amount) <
        parseFloat(this.radioGroup.lowestInvestmentAmount)
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "investment Amount must be greater than or equal Lowest Investment Amount"
        );
        return;
      }
      if (
        parseFloat(this.amount) >
        parseFloat(this.radioGroup.hightestInvestmentAmount)
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "investment Amount should not be greater than Hightest Investment Amount"
        );
        return;
      }
      if (this.radio == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Select coin type");
        return;
      }
      let payload = {
        amount: this.amount,
        coin: this.radio,
        ...this.radioGroup,
      };
      this.$store.commit("setInvestmentPlan", payload);
      this.$router.push({ name: "DepositDetails" });
    },

    GetInvestmentPlan() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("GetInvestmentPlan", payload);
    },
  },
  computed: {
    getInvestmentPlan() {
      return this.$store.getters.getInvestmentPlan;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
};
</script>